<template>
	<LayoutWide>
		<div>
			<SubNavbar page="Utilities" />
		</div>
		<div v-if="showSearch"><SelectCustomer /></div>
		<div v-if="showHistory"><CustomerInvoices /></div>
	</LayoutWide>
</template>

<script>
import SelectCustomer from '@/_srcv2/pages/admin/customer-history/sub-components/SelectCustomer.vue'
import CustomerInvoices from '@/_srcv2/pages/admin/customer-history/sub-components/CustomerInvoices.vue'
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar.vue'
import storeCustomerHistory from '@/_srcv2/pages/admin/customer-history/store/useCustomerHistoryStore.js'
import { onMounted } from '@vue/composition-api'

export default {
	name: 'CustomerHistory',
	components: {
		SelectCustomer,
		CustomerInvoices,
		SubNavbar,
	},
	setup() {
		const { showSearch, showHistory } = storeCustomerHistory()
		onMounted(() => {
			showSearch.value = true
			showHistory.value = false
		})
		return {
			showSearch,
			showHistory,
		}
	},
}
</script>

<style scoped></style>
